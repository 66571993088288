import { Link, LinkProps, Text } from '@chakra-ui/react'
import Trans from 'next-translate/Trans'
import React, { FunctionComponent } from 'react'

const LinkComponent = (props: LinkProps) => (
  <Link {...props} variant="secondary" rel="noopener noreferrer noindex" target="_blank" />
)

const COMPONENTS = {
  TermsOfService: <LinkComponent href={process.env.NORMET_TERMS_OF_SERVICE_URL} />,
  DataProtectionNotice: <LinkComponent href={process.env.NORMET_DATA_PROTECTION_NOTICE_URL} />,
}

const TAG = 'LoginTermsAndConditions'

const LoginTermsAndConditions: FunctionComponent = () => (
  <Text>
    <Trans ns="auth" i18nKey="login:accept-terms-and-conditions" components={COMPONENTS} />
  </Text>
)

LoginTermsAndConditions.displayName = TAG

export default LoginTermsAndConditions


    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import type { NextPage } from 'next';
import LoginLayout from 'components/layouts/LoginLayout';
import LoginMain from 'components/modules/Login/LoginMain';
const TAG = 'LoginPage';
const LoginPage: NextPage = () => {
    return (<LoginLayout>
      <LoginMain />
    </LoginLayout>);
};
LoginPage.displayName = TAG;
export default LoginPage;

    async function __Next_Translate__getStaticProps__19504e23c31__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19504e23c31__ as getStaticProps }
  
import { useMsal } from '@azure/msal-react'
import { Box, Button, Divider, Heading, Link } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import LoginTermsAndConditions from 'components/modules/Login/LoginTermsAndConditions'
import { loginRequest, resetPasswordRequest } from 'config/auth'

const TAG = 'LoginMain'

const styles = {
  heading: { size: 'xl', mb: 10 },
  wrapper: { textAlign: 'end', mt: 6 },
  primaryButton: { mt: 4, textTransform: 'uppercase' },
  button: { w: 'full', size: 'lg', mb: 6 },
  divider: { mt: 4, mb: 6, borderBottomWidth: 1 },
} as const

const LoginMain: FunctionComponent = () => {
  const { t } = useTranslation('login')

  const { instance } = useMsal()

  const signIn = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e)
    })
  }

  const forgotPassword = () => {
    instance.loginRedirect(resetPasswordRequest).catch((e) => {
      console.error(e)
    })
  }

  return (
    <>
      <Heading as="h1" {...styles.heading}>
        {t('sign-in-to-normet')}
      </Heading>
      <LoginTermsAndConditions />
      <Button onClick={signIn} {...styles.primaryButton}>
        {t('sign-in')}
      </Button>
      <Box {...styles.wrapper}>
        <Button
          onClick={forgotPassword}
          aria-label={t('forgot-password-text')}
          variant="ternary"
          color="primary.red"
          fontWeight="normal"
        >
          {t('forgot-password-text')}
        </Button>
      </Box>
      <Divider {...styles.divider} />
      <Button
        as={Link}
        href={process.env.NORMET_NO_ACCESS_TO_STORE_URL}
        variant="secondary"
        aria-label={t('new-user')}
        rel="noopener noreferrer"
        {...styles.button}
      >
        {t('new-user')}
      </Button>
    </>
  )
}

LoginMain.displayName = TAG

export default LoginMain
